.header-color{
  background: #ffffff;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, white, white, white);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, white, white, white); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
}

.header-color a:hover{
  transition: all .3s ease-in-out;
  transform:scale(1.1,1.1);
  text-decoration: underline;
}




.landing-grid{
  background: #D3CCE3;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #E9E4F0, #D3CCE3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: white;
  /* background: black; */

  text-align: center;
  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  height: 100vh;
  overflow: auto;
  margin-top: 0;
  padding-top: 0;
}

.avatar-img {
  height: 120px;
  margin-top: 1em;
  border-radius: 50%;
  /* transform: rotate(-1.25deg); */
}

.banner-text {
  /* background-color: black; */
  opacity: .8;
  width: 55%;
  margin: auto;
  border-radius: 10px;
  font-family: montserrat;
  
}

.banner-text h1{
  font-size: 4vw;
  font-weight: bold;
  font-family: montserrat;
}
@media screen and (max-width: 600px) {
  .banner-text h1 {
    font-size: 5vh;
  }
}

.banner-text h2{
  font-size: 3vw;
  font-family: montserrat;
}
@media screen and (max-width: 600px){
  .banner-text h2 {
    font-size: 4vh;
  }
}

.banner-text hr {
  border-top: 5px dashed white;
  width: 50%;
  margin: auto;
}

.banner-text p{
  color: black;
  font-size: 18px;
  padding: 1em;
  font-family: montserrat;
  line-height: 1.5em;
  
}

.social-links {
  /* display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
  margin-top: -30px; */
}

.social-links i {
  color: black;
  font-size: 5em;
  padding: 8px;
  transition: all .1s ease-in-out;
}
.social-links i:hover{
  transform:scale(1.1,1.1);
}

@media screen and (max-width: 600px) {
  .social-links{
    width: 50vw;
  }
  .social-links i {
    font-size: 20vw;
  }
}

.resume-grid{
  background: #ffffff;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffffff, #ffffff);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffffff, #ffffff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  text-align: center;
  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  height: 100%;
  margin: auto;
}

.resume-grid .job-title{
  display: flex;
  align-items: center;
  justify-content: center;
}

.resume-grid h1{
  color: black;
  font-family: montserrat;
  font-size: 12pt;
  font-weight: bold;
}

.resume-grid h2{
  font-family: montserrat;
  size: 10px;
}
.resume-grid p{
  font-family: montserrat;
}

.mdl-layout__drawer-button{
  color: black;
}
.resume-grid h3{
  color: white;
  font-family: montserrat;
  text-align: center;
  text-shadow: -2px 2px 5px black;
}

@media screen and (max-width: 600px){
  .resume-grid h3{
    text-align: left;
    margin: auto;
    padding-left: 5px;
    margin-top: -5%;
    margin-bottom: -1%;
    margin-left: -5px;
    font-size: 5vw;
  }
}
.resume-grid p{
  color: black;
  font-family: montserrat;
  text-align: left;
  /* margin-bottom: 0%; */
}

.resume-grid .date{
  padding-bottom: 1px;
}
@media screen and (max-width: 600px){
  .resume-grid .date{
    margin-bottom: 0px;
  }
}

.resume-grid hr{
  border-top: 5px dotted white;
  margin-left: 20%;
  margin-right: 20%;
}

.projects-grid{
  background: #D3CCE3;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #E9E4F0, #D3CCE3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background: white;
  display: flex;

  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.projects-grid .project-card{
  margin: auto;
}

@media screen and (max-width: 600px) {
  .projects-grid {
    margin-top: 20%;
    display: block;
  }

  .projects-grid .project-card{
    margin-bottom: 10%;
    background: white;
  }
}



.card-title{
  height: 176px;
  width: 100%;
  /* text-align: center; */
  background-size: 50% 50%;
}

.card-text{
  min-height: 70px;
}